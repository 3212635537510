nav#gmenu{ display: none}

.p-header{
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  padding: 0 0 0 50px;
  margin-bottom: 0px;
  z-index: 1;
  @media (max-width:1200px) {
    padding-left: 30px;
    padding-right: 0px;
  }
  &__logo{
    .hoverOpacity;
    margin: 0 60px 0 0;
    padding: 23px 0;
    &-link{
      display: block;
      img{
        display: block;
        max-width: 100%;
      }
    }
  }
  &__nav{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 600px;
    margin-right: 20px;
    margin-left: auto;
  }
  &__tel{
    color: @secondary;
    margin: 0;
    padding: 16px 0 0;
    span{
      font-size: 2.6rem;
      font-family: 'Lato';
      font-weight: 600;
      margin-right: 1em;
    }
    small{
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
  &__contact{
    display: grid;
    grid-template-columns: repeat(2,111px);
    grid-template-rows: 1fr;
    >a{
      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      color: @white;
      font-weight: 600;
      line-height: 1.73;
      .transition(all @transition-fast ease-in-out);
      &:hover,&:focus{
        text-decoration: none;
        color: @white;
      }
    }
    >a:nth-child(1){
      grid-column: 1;
      background-color: #FF88A2;
      &:hover,&:focus{
        background-color: @primary;
      }
    }
    >a:nth-child(2){
      grid-column: 2;
      background-color: #8AD3CC;
      &:hover,&:focus{
        background-color: @secondary;
      }
    }
  }
}
.p-gnav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: @body-color;
  &__item{
    position: relative;
    color: inherit;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0.04em;
    padding: 1rem;
    &:after{
      position: absolute;
      bottom: 5px; left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 0;
      height: 2px;
      .transition(all @transition-fast ease-in-out);
      background-color: @primary;
    }
    &:not(.button):hover,&:not(.button):focus,&.current{
      text-decoration: none;
      color: @body-color;
      &:after{
        width: 80%;
      }
    }
  }
}

.mainImg{
  margin-bottom: 10px;
  & &Inner{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    background: transparent url('/asset/img/about/about_mainimg@2x.jpg') 50% bottom / cover no-repeat; 
    height: 230px;
    >h2{
      &:extend(.c-content-title all);
    }
  }
}