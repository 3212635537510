@breadcrumnbs-color: @body-color;
@breadcrumnbs-arrow-color: @primary;

.breadcrumbs{
  margin-top: 0;
  margin-bottom: 2.7em;
  padding: 0;
  max-width: 100%;
  ol{
    display: flex;
    align-items: center;
    padding: 10px ~'calc(50vw - 480px)';
    background: none;
    color: @breadcrumnbs-color;
    max-width: 100%;
    li{
      color: inherit;
      letter-spacing: 1px;
      line-height: 1;
      // text-shadow: 1px 1px 7px #ffffff, -1px -1px 7px #ffffff, 1px -1px 7px #ffffff, -1px 1px 7px #ffffff;
      &:first-child:before{
        font-family: 'fontAwesome';
        content: none;
        display: inline-block;
        color: inherit;
      }
      &:first-child a{
        padding-left: 0;
        display: inline-block;
      }
      >span{
        padding-left: 0.4em;
        padding-top: 0;
        line-height: 1;
        font-size: 1.4rem;
        // padding-top: 5px\0;
      }
      a,a:first-child{
        display: flex;
        align-items: center;
      }
      a,
      a:visited{
        background: none;
        line-height: 1;
        padding-left:0.4em;
        padding-right: .5em;
        padding-top: 0;
        // padding-top: 5px\0;
        color: inherit;
        font-size: 1.4rem;
      }
      a:hover{
        text-decoration: none;
        span{
          text-decoration: underline;
        }
      }
      a:hover,
      a:hover span{
        color: tint(@breadcrumnbs-color, 30%);
      }
      a:before,
      a:after{
        border:none;
        display: none;
      }
      a:after{
        position: static !important;
        display: block;
        margin-left: 0.4em;
        margin-right: 0.1em;
        font-family: 'fontAwesome';
        content:'\f105';
        // margin-top: 0;
        margin-top: ~"-5px\0";
        line-height: 0;
        font-size: 1.8rem !important;
        color: @breadcrumnbs-arrow-color;
      }
      a:hover,
      a:focus{
        background: none;
        // color:inherit;
        // text-decoration: underline;
      }
    }
  }
}