// #main .element_detail .entry
.detail-large-thumbnail{
  >.imgbox{
    float: none;
    padding: 0;
    width: auto;
    margin: 0 auto 30px; 
    >div>table{
      margin-left: auto;
      margin-right: auto;
    }
    td>a>img{
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 600px;
      margin: 0 auto;
    }
    .captipntext{
      max-width: 100%;
    }
  }
}