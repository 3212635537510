#side{
  .block .element_side_block{
    >.title{
      border: none;
      display: block;
      &:hover{
        background: none;
      }
      h4{
        display: block;
        height: auto;
        padding-left: 0;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        margin-bottom: 0;
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: .1em;
        border-top: 1px solid @primary;
      }
    }
    >.contents.contents_text a{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    >.contents.contents_text a .contents{
      border-top: 0;
      padding: 4px 0;
      >h5.titleOnly{
        padding-left: 0;
      }
    }
    >.contents_cat{
      .cat>li{
        padding-left: 0;
        margin-bottom: 1px;
      }
      .cat>li a{
        position: relative;
        padding: 1rem;
        background: #eef0f0;
        border-width: 0;
        font-weight: 600;
        &:after{
          position: absolute;
          right: 1rem; top: 50%;
          line-height: 0;
          font-size: 1.2em;
          font-family: FontAwesome;
          content: '\f105';
        }
        &:hover,
        &:focus,
        &.current{
          text-decoration: none;
          background-color: @primary;
          color: @white;
        }
      }
    }
  }
}
.sideBanner{}
.sideSocial{
  list-style: none;
  margin: 20px 0;
  text-align: center;
  li{
    display: inline-block;
    font-family:  'Montserrat', sans-serif;
    font-size: 16px;
    color: #C8C8C8;
    letter-spacing: 1px;
    margin: 0 5px;
    padding: 0;
    .like, .tweet {
      display: block;
      color: #FFFFFF;
      font-size: 14px;
      line-height: 24px;
      padding: 0 10px;
      &:before{
        font-family: FontAwesome;
      }
    }
    .like {
      background-color: #4267B1;
      &:before{
        content: '\f087';
      }
    }
    .tweet {
      background-color: #1B94DF;
      &:before{
        content: '\f099';
      }
    }
  }
}