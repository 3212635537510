/*
 * Title
 */

.c-content-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  color: @body-color;
  line-height: 1.75;
  span{
    font-size: 4rem;
    color: inherit;
    font-family: 'Jost', sans-serif;
    line-height: 1.1;
  }
  small{
    display: block;
    font-size: 1.5rem;
    color: @primary;
    font-weight: 600;
    letter-spacing: 0.03em;
  }
}

.c-title-block{
  text-align: center;
  font-size: 44px;
  margin-bottom: 1em;
  line-height: 1.65;
  @media @xsmall{
    font-size: 35px;
  }
  span{
    display: block;
    font-family: 'Jost', sans-serif;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-indent: 0.1em;
    line-height: 1.3;
    @media @xsmall{
      letter-spacing: .2em;
      text-indent: .2em;
    }
  }
  small{
    display: block;
    font-size: 0.375em;
    letter-spacing: 0em;
    text-indent: 0em;
    color: @primary;
    font-weight: 600;
  }
}

.c-title-bottom-border{
  font-size: 2.4rem;
  letter-spacing: 0.1em;
  text-indent: 0.1em;
  color: @body-color;
  line-height: 1.5;
  text-align: center;
  font-weight: 600;
  position: relative;
  @media @xsmall{
    text-indent: .1em;
    letter-spacing: .1em;
    font-size: 1.8rem;
    padding-bottom: 0;
    border-bottom: none;
  }
  &,div.entry &{
    margin-bottom: 80px;
    @media @xsmall {
      margin-bottom: 50px;
    }
  }
  &:after{
    content: '';
    display: block;
    margin: 0 auto;
    border-bottom: 3px solid @primary;
    width: 100px;
    position: absolute;
    left: 0;right: 0;
    bottom: -20px;
  }
}