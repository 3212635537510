// ブロックのhover設定
@background-linkarea: fade(@dark-grey,3%);

@new-bg: @error;
@new-color: @white;

#main > .block .artloop:hover,
#main > .block .element_block .artloop:hover,
#side > .block .artloop:hover,
#side > .block .element_side_block .artloop:hover,
#main > .element_list .artloop:hover{
  background: transparent;
}
#main > .block .element_block .wrapLink:hover,
#main > .element_list .wrapLink:hover,
#side > .block .element_side_block .wrapLink:hover{
  background-color: @background-linkarea;
}

// Newマーク設定
#main > .block .element_block h5 > .new,
#side > .block .element_side_block h5 > .new,
#main > .element_list h5 > .new,
#main > .block .element_block h5 + .new,
#side > .block .element_side_block h5 + .new,
#main > .element_list h5 + .new{
  .hide-text;
  &:after{
    background-color: @new-bg;
    color: @new-color;
  }
}
