// ページング(prev/next)
@paging-button-bg: transparent;
@paging-button-hover-bg: transparent;
@paging-button-disable-bg: transparent;
@paging-button-border: 0px solid @body-color;
@paging-button-hover-border: 0px;
@paging-button-disable-border: 0px solid @light-grey;
@paging-button-color: @body-color;
@paging-button-hover-color: @primary;
@paging-button-disable-color: tint(@body-color, 20%);

@paging-backbtn-bg: transparent;
@paging-backbtn-hover-bg: transparent;
@paging-backbtn-color: @body-color;
@paging-backbtn-hover-color: @primary;
@paging-backbtn-border: transparent;

@paging-num-color: @white;
@paging-num-hover-color: @white;
@paging-num-bg: @light-grey;
@paging-num-hover-bg: @body-color;
@paging-icon-color: @primary;
@paging-icon-hover-color: @primary;

#main .paginationbox{
  .pageNav{
    width: auto;
    margin: 0 auto;
  }
  .prev,.next{
    width: 11em;
    font-weight: 600;
    color: @paging-button-color;
    letter-spacing: .3em;
    background: @paging-button-bg;
    border: @paging-button-border;
    .transition(background @transition-fast ease-in-out);
  }
  .prev:before,
  .next:after{
    font-family: 'fontAwesome';
  }
  .prev:before{
    margin-right: .3em;
    content:'\f053';
  }
  .next:after{
    margin-left: .3em;
    content:'\f054';
  }
  .prev:not(.disable):before,
  .next:not(.disable):after{
    color: @paging-icon-color;
  }
  .prev.disable,.next.disable{
    color: @paging-button-disable-color;
    background: @paging-button-disable-bg;
    border: @paging-button-disable-border;
    opacity: 0.7;
  }
  .prev:hover:not(.disable),
  .next:hover:not(.disable){
    color: @paging-button-hover-color;
    background: @paging-button-hover-bg;
    border: @paging-button-hover-border;
    &:before, &:after{
      color: @paging-icon-hover-color;
    }
  }
  .pages{
    padding: 0 15px;
  }
  .pages li{
    position: relative;
    .hoverUnderline(3px,@paging-icon-color);
    font-family: 'Roboto';
    span{
      color: @paging-num-color;
      background: @paging-num-hover-bg;
    }
    a{
      .transition(background @transition-fast ease-in-out);
      color: @paging-num-color;
      background: @paging-num-bg;
      &:hover{
        background: @paging-num-hover-bg;
      }
    }
    &:after{
      left:50%;
      position: absolute;
      bottom:0;
    }
    &:hover:after,
    &[class*='current']:after,
    &[class*='active']:after{
      display: none;
    }
  }
  .backbtn{
    padding: 0 15px;
  }
  .backbtn a{
    white-space: nowrap;
    padding: 12px 15px;
    width: 100%;
    color: @paging-backbtn-color;
    background: @paging-backbtn-bg;
    border: @paging-backbtn-border;
    &:hover{
      background: @paging-backbtn-hover-bg;
      color: @paging-backbtn-hover-color;
    }
  }
  .totalpages{
    padding-left: 10px;
    padding-right: 10px;
  }
}