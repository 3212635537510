/*
 * Font Size
 */

// Font Size 
.font-size-xlarge {
	.font-size(@font-size-xlarge);
}

// Font Size 
.font-size-large {
	.font-size(@font-size-large);
}

// Font Size 
.font-size-medium {
	.font-size(@font-size-medium);
}

// Font Size 
.font-size-small {
	.font-size(@font-size-small);
}

// Font Size 
.font-size-xsmall {
	.font-size(@font-size-xsmall);
}