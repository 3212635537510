/*
 * Definitions
 */

// Horizontal Rule
@hr-margin: 3rem auto;
@hr-height: 1px;
@hr-background: @light-grey;


/*
 * Horizontal Rule
 */

hr {
	clear: both;
	margin: @hr-margin;
	border: 0;
	height: @hr-height;
	background: @hr-background;
}