.c-contactBlock{
  padding: 50px 40px 60px;
  text-align: center;
  color: @white;
  &.is-contact{ background-color: #FF88A2;}
  &.is-recruit{ background-color: #8AD3CC;}
  &__title{
    color: inherit;
    font-size: 2.4rem;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-indent: 0.1em;
  }
  &__tel{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    font-size: 2.1rem;
    margin-bottom: 2em;
    @media @xsmall {
      font-size: 1.6rem;
      margin-bottom: 1.8em;
    }
    .tel{
      display: block;
      color: inherit;
      font-family: 'Montserrat', sans-serif;
      font-size: 3.8rem;
      font-weight: 600;
      letter-spacing: 0.05em;
      white-space: nowrap;
      @media @small-up {
        font-size: 4.4rem;
        pointer-events: none;
      }
    }
    strong{
      display: block;
      margin-bottom: 0.4em;
    }
  }
  &__lead{
    font-size: 1.5rem;
    color: inherit;
    letter-spacing: 0.05em;
    line-height: 1.73;
    font-weight: 600;
    margin-bottom: 3em;
    @media @xsmall {
      text-align: left;
    }
  }
  .button-medium{
    width: 100%;
    max-width: 400px;
    padding: 1.4em 2em;
    font-weight: 600;
    letter-spacing: 0.05em;
    border-radius: 5px;
    @media all and (-ms-high-contrast:none){
      *::-ms-backdrop, &:before{
        content: '';
        display: block;
        width: 100%;
        height: 0.2em;
      }
    }
  }
}