.antialias(@antialias:auto){
  -webkit-font-smoothing: @antialias;
  -moz-osx-font-smoothing: @antialias;
}

.hoverOpacity(@opacity:.8){
  .transition(opacity @transition-fast ease-in-out);
  &:hover{
    opacity: @opacity;
  }
}
.hoverUnderline(@border-width,@border-color){
  &:after{
    display: block;
    content:'';
    width: 0%;
    height: 0;
    border-bottom: @border-width solid @border-color;
    position: relative;
    margin: 0 auto;
    .transition(all @transition-fast ease-in-out);
  }
  &:hover{
    text-decoration: none;
  }
  &:hover:after,
  &:focus:after,
  &[class*="current"]:after,
  &[class*="active"]:after{
    width: 100%;
  }
}

.hybs-one-column(@container-width:100%){
  #container{
    width: @container-width;
  }
  #main,#side{
    width: 100%;
  }
  #container,#main,#side{
    float: none;
    max-width: none;
    &:before,&:after,br[clear]{
      display: none;
    }
  }
  #side,
  .detailfooter .totalpages{
    display: none;
  }
  #container{
    &.main-right{
      #main,#side{ float: none;}
    }
  }
  @media @xsmall{
    .ui-content{
      padding-left: 0;
      padding-right: 0;
    }
    .element_freearea{
      margin-bottom: 0;
    }
  }
}