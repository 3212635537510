/*
 * Utility Classes
 */
 
.clearfix {
	.clearfix();
}

.center-block {
	.center-block();
}

.cover {
	.cover();
}

.center {
	.center();
}

.full-height {
	height: 100%;
}

.fixed {
	position: fixed;
}

.pointer {
	cursor: pointer;
}

.no-margin {
	margin: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.no-border {
	border: none !important;
}