.c-cont__nav{
  margin-top: 75px;
  display: flex;
  justify-content: center;
  @media @xsmall {
    padding: 0 5px;
  }
  .button{
    display: block;
    width: 100%;
    max-width: 280px;
    margin: 0 15px;
    @media @xsmall {
      margin: 0 3px;
    }
  }
  .button-medium{
    padding: 1.4em 2em;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-indent: 0.05em;
    border-radius: 5px;
    @media @xsmall {
      padding: 15px 5px;
      font-size: 1.3rem;
      white-space: nowrap;
    }
    @media all and (-ms-high-contrast:none){
      *::-ms-backdrop, &:before{
        content: '';
        display: block;
        width: 100%;
        height: 0.2em;
      }
    }
  }
}