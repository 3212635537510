@keyframes up_down_arrow {
  0%   { margin-top: 0; }
  50%  { margin-top: -15px; }
}

footer{
  // background-color: @primary;
  color: @body-color;
  >a.pagetop{
    display: none;
  }
  .promotion{
    margin-bottom: 0;
    padding: 1em;
  }
  .copyright{
    margin-bottom: 0.5em;
  }
}

.p-gotop{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 20px;
  bottom: -128px;
  .square(108px);
  background-color: @tertiary;
  font-size: 1.8rem;
  color: @white;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  text-align: center;
  border-radius: 100%;
  line-height: 1.2;
  letter-spacing: 0.05em;
  &:before{
    display: block;
    content: '';
    .square(15px);
    border: 1px solid @white;
    border-width: 2px 2px 0 0;
    .rotate(-45deg);
  }
  &:focus{
    color: @white !important;
    text-decoration: none !important;
  }
  &:hover{
    color: @white !important;
    text-decoration: none !important;
    &:before{
      animation: up_down_arrow 1s infinite ease-in-out;
    }
  }
  &__wrap{
    width: 100%;
    position: fixed;
    position: sticky;
    bottom: 148px;
    z-index: 10;
    .transition(all @transition-fast ease-in-out);
    &.hidden{
      .translateY(200px);
      opacity: 0;
    }
    @media @xsmall{
      display: none;
    }
  }
}

.p-contfooter{}

.p-footer{
  padding: 80px 15px 50px;
  &__logo{
    .hoverOpacity;
    display: inline-block;
  }
  &__head{
    margin-bottom: 70px;
  }
  &__addr{
    font-size: 1.6rem;
  }
  &__social{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    letter-spacing: .07em;
    font-size: 1.6rem;
    >span{
      margin-right: 1em;
      color: @body-color;
      line-height: 2;
      @media @xsmall{
        display: block;
        width: 100%;
        margin-right: 0;
      }
    }
    .btn{
      color: @white;
      margin: 0 .5em;
      padding: 0.1em 0.7em;
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: 300;
      border-radius: 5px;
      .fa{ margin-right: .5em;}
      &:hover,
      &:focus{
        text-decoration: none;
        color: @white;
      }
    }
    .is-fb{
      background-color: @sns-facebook-bg;
      &:hover{ background-color: darken(@sns-facebook-bg,10%);}
    }
    .is-tw{
      background-color: @sns-twitter-bg;
      &:hover{ background-color: darken(@sns-twitter-bg,10%);}
    }
  }
  &__nav{
    margin-bottom: 1em;
    &:last-child{
      margin-bottom: 0;
    }
    a{
      display: inline-block;
      padding: 0 1em;
      border-right: 1px solid @body-color;
      color: inherit;
      font-size: 15px;
      &:last-child{ border-right: 0;}
      &:hover, &:focus{
        text-decoration: none;
        color: shade( @white, 20%);
      }
    }
  }
}