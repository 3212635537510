@font-face {
  font-family: 'Lato';
  src: url('@{fontfile_path}Lato/Lato-Italic.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-Italic.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-Italic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-Italic.svg#51ac4eb0bc1817d276ca824c5353e08f') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  400;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('@{fontfile_path}Lato/Lato-Hairline.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-Hairline.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-Hairline.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-Hairline.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-Hairline.svg#477d11e773543baa2ffc5ab40fca9623') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Lato';
  src: url('@{fontfile_path}Lato/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-Regular.svg#48e70b8825d557df57af3e4f7d4c31be') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Lato';
  src: url('@{fontfile_path}Lato/Lato-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-BoldItalic.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-BoldItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-BoldItalic.svg#030f5996dac0d7b15fbd4081adedf95b') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  700;
}

@font-face {
  font-family: 'Lato Black';
  src: url('@{fontfile_path}Lato/Lato-Black.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-Black.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-Black.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-Black.svg#deafb22e0fea74c18abc97a942bb621e') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Lato Light';
  src: url('@{fontfile_path}Lato/Lato-LightItalic.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-LightItalic.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-LightItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-LightItalic.svg#7015aa8810c4a9d1026a85e4ddcdf7a4') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  200;
}

@font-face {
  font-family: 'Lato Light';
  src: url('@{fontfile_path}Lato/Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-Light.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-Light.svg#80d8021909d835b751af094d043ad177') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  200;
}

@font-face {
  font-family: 'Lato';
  src: url('@{fontfile_path}Lato/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-Bold.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-Bold.svg#eea591db52cf6ebc8992abb7621b9256') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'Lato Black';
  src: url('@{fontfile_path}Lato/Lato-BlackItalic.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-BlackItalic.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-BlackItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-BlackItalic.svg#6b8da3d2951ae28f306d4067aff6fc5c') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  400;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('@{fontfile_path}Lato/Lato-HairlineItalic.eot'); /* IE9 Compat Modes */
  src: url('@{fontfile_path}Lato/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{fontfile_path}Lato/Lato-HairlineItalic.woff') format('woff'), /* Modern Browsers */
       url('@{fontfile_path}Lato/Lato-HairlineItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('@{fontfile_path}Lato/Lato-HairlineItalic.svg#a326329371e687b71c219b7d91fb9255') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  400;
}

